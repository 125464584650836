











































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CheckoutDetails, ReservationExpiration } from '@openticket/lib-shop';
import { intToDurationObject } from '@openticket/lib-utils';
import { IntSplitDuration } from '@openticket/lib-utils/lib/int_to_duration';
import { intToDuration } from '@/utils';
import OrderBarTotal from './OrderBarTotal.vue';

@Component({
    components: { OrderBarTotal },
})
export default class OrderBar extends Vue {
    @Prop() action!: string;
    @Prop() valid!: boolean;
    @Prop() visible!: boolean;
    @Prop() hasNextPage!: boolean;
    @Prop() disabled!: boolean;
    @Prop({ default: true }) showTotal!: boolean;

    details: CheckoutDetails | null = null;

    busy = false;
    busyTimer: number | null = null;

    isMounted = false;

    checkoutListener!: string;
    timerListener!: string;

    timer: number | null = null;

    get timerDuration(): string {
        return intToDuration(this.timer || 0);
    }

    get timerDurationObject(): IntSplitDuration {
        return intToDurationObject(this.timer || 0);
    }

    get showReservationTimer(): boolean {
        return (
            !this.$settings ||
            !this.$settings.static ||
            !this.$settings.static.shop ||
            this.$settings.static.shop.showReservationTimer ||
            this.$settings.static.shop.showReservationTimer === null
        );
    }

    public created(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Loc = this.$time;

        this.checkoutListener = this.$shop.cart.on(
            'checkout',
            (path: string[], details: CheckoutDetails) => {
                // Update details
                this.details = details;

                // If there is already a timer running, stop it
                if (this.busyTimer) {
                    clearTimeout(this.busyTimer);
                    this.busyTimer = null;
                }

                if (details.busy) {
                    // if busy, start the new timer
                    this.busyTimer = window.setTimeout(() => {
                        this.busy = true;
                    }, 150);
                } else {
                    this.busy = false;
                }
            }
        );

        this.details = this.$shop.cart.checkout_details;

        this.timerListener = this.$shop.events.on(
            ['timer'],
            async (path: string[], data: ReservationExpiration) => {
                this.timer = data.secondsRemaining;
            }
        );
    }

    mounted(): void {
        this.isMounted = true;
    }

    nextPage(): void {
        this.$emit('next');
    }

    placeOrder(): void {
        this.$emit('place-order');
    }

    destroyed(): void {
        this.$shop.off(this.checkoutListener);
        this.$shop.off(this.timerListener);
    }
}
