



























































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { PricingDetails } from '@openticket/lib-shop';
import { CartItemsEvent } from '@openticket/lib-shop';
import { Prop, Ref } from 'vue-property-decorator';
import { intToDuration, getSelectorClass } from '@/utils';
import MarkdownRenderer from '@/pages/shop/components/MarkdownRenderer.vue';

const COUNTDOWN_THRESHOLD = 60 * 30; // In seconds

export type ItemSplit = {
    [count: number]: number;
};

@Component({
    components: { MarkdownRenderer },
})
export default class ShopGroupItem extends Vue {
    @Prop({
        default: false,
    })
    private disableDescription!: boolean;

    @Prop({
        default: false,
    })
    private openDescription!: boolean;

    @Ref('seats-dialog')
    protected seatsDialog!: HTMLDialogElement;

    // Used to construct the right elem selector
    protected modelType: string | null = null;
    protected guid: string | null = null;

    protected title!: string;
    protected description!: string;
    protected pricing: PricingDetails = {
        base_price: 0,
        total_fees: 0,
        subtotal_price: 0,
        total_price: 0,
    };
    protected status: string | null = null;
    protected type: 'default' | 'seated' = 'default';
    protected groupSize = 1;

    protected countdown: number | null = null;
    protected available_from: Date | null = null;

    protected count = 0; // i.e. the cart count (use the totalCount for most usecases!)
    protected inFlightCount = 0; // i.e. To give users early feedback
    protected price = 0;
    protected counts: ItemSplit = [];

    public showSeatsDialog = false;

    private internalIsLoading = 0;

    protected get groupItemModelModifier(): string {
        return `group-item--${this.modelType}`;
    }

    protected get groupItemSelectorClass(): string {
        if (!this.guid || !this.modelType) {
            return '';
        }

        return getSelectorClass(this.modelType, this.guid);
    }

    protected set isLoading(val: boolean) {
        if (val) {
            this.internalIsLoading++;
        } else {
            this.internalIsLoading--;
        }
    }

    protected get isLoading(): boolean {
        return this.internalIsLoading > 0;
    }

    get totalCount(): number {
        return this.count + this.inFlightCount;
    }

    protected get countdownString(): string {
        if (this.countdown === null) {
            return '';
        }

        if (this.countdown < 0) {
            return this.$t(
                'shop.common.ticket_status.not_sold_right_now'
            ) as string;
        }

        if (this.countdown < COUNTDOWN_THRESHOLD) {
            return intToDuration(this.countdown, true);
        }

        return this.available_from
            ? this.$l.dateTime(
                  this.available_from,
                  Intl.DateTimeFormat().resolvedOptions().timeZone
              )
            : '';
    }

    protected get statusSlug(): string {
        switch (this.status) {
            case 'available':
                return 'shop.common.ticket_status.available';
            case 'not_sold_right_now':
                return 'shop.common.ticket_status.not_sold_right_now';
            case 'sold_out':
                return 'shop.common.ticket_status.sold_out';
        }

        // If not recognized, just return the status.
        // This 'slug' will most likely not be present in translation messages
        // and thus be shown as-is.
        return this.status || '';
    }

    protected get pricingStrings(): { [key: string]: string } {
        if (!this.$shop.data.currency) {
            return {
                price_total: '?',
                base_price: '?',
                subtotal_price: '?',
                total_price: '?',
                total_fees: '?',
            };
        }

        return {
            price_total: this.$l.currency(this.price, this.$shop.data.currency),
            base_price: this.$l.currency(
                this.pricing.base_price,
                this.$shop.data.currency
            ),
            subtotal_price: this.$l.currency(
                this.pricing.subtotal_price,
                this.$shop.data.currency
            ),
            total_price: this.$l.currency(
                this.pricing.total_price,
                this.$shop.data.currency
            ),
            total_fees: this.$l.currency(
                this.pricing.total_fees,
                this.$shop.data.currency
            ),
        };
    }

    protected get itemFeeSlug(): string | null {
        if (!this.pricing.total_price || !this.pricing.total_fees) {
            return null;
        }

        switch (this.modelType) {
            case 'ticket':
                if (
                    this.$te('shop.components.shop_group_item.ticket.item_fee')
                ) {
                    return 'shop.components.shop_group_item.ticket.item_fee';
                }

                break;
            case 'product':
                if (
                    this.$te('shop.components.shop_group_item.product.item_fee')
                ) {
                    return 'shop.components.shop_group_item.product.item_fee';
                }

                break;
        }

        return 'shop.components.shop_group_item.item_fee';
    }

    protected get itemPriceSlug(): string {
        switch (this.modelType) {
            case 'ticket':
                if (this.pricing.total_price) {
                    if (
                        this.$te(
                            'shop.components.shop_group_item.ticket.item_price'
                        )
                    ) {
                        return 'shop.components.shop_group_item.ticket.item_price';
                    }
                } else if (
                    this.$te('shop.components.shop_group_item.ticket.item_free')
                ) {
                    return 'shop.components.shop_group_item.ticket.item_free';
                }

                break;
            case 'product':
                if (this.pricing.total_price) {
                    if (
                        this.$te(
                            'shop.components.shop_group_item.product.item_price'
                        )
                    ) {
                        return 'shop.components.shop_group_item.product.item_price';
                    }
                } else if (
                    this.$te(
                        'shop.components.shop_group_item.product.item_free'
                    )
                ) {
                    return 'shop.components.shop_group_item.product.item_free';
                }

                break;
        }

        if (this.pricing.total_price) {
            return 'shop.components.shop_group_item.item_price';
        }

        return 'shop.components.shop_group_item.item_free';
    }

    protected get totalPriceSlug(): string {
        switch (this.modelType) {
            case 'ticket':
                if (
                    this.$te(
                        'shop.components.shop_group_item.ticket.price_total'
                    )
                ) {
                    return 'shop.components.shop_group_item.ticket.price_total';
                }

                break;
            case 'product':
                if (
                    this.$te(
                        'shop.components.shop_group_item.product.price_total'
                    )
                ) {
                    return 'shop.components.shop_group_item.product.price_total';
                }

                break;
        }

        return 'shop.components.shop_group_item.price_total';
    }

    private showDescription = false;

    mounted(): void {
        if (
            this.status === 'available' &&
            this.description &&
            this.openDescription
        ) {
            this.showDescription = true;
        }
    }

    protected async add(): Promise<CartItemsEvent> {
        // Implement
        throw Error('ShopGroupItem.add() not implemented');
    }

    protected async remove(): Promise<CartItemsEvent> {
        // Implement
        throw Error('ShopGroupItem.remove() not implemented');
    }

    protected async open(): Promise<void> {
        // Implement
    }

    protected update(event: CartItemsEvent): void {
        this.count = event.count;
        this.counts = event.counts;
        this.price = event.pricing.total_price;
    }

    private async _add() {
        this.isLoading = true;

        const { groupSize } = this;
        this.inFlightCount += groupSize;

        try {
            const event: CartItemsEvent = await this.add();
            this.update(event);
            this.$emit('change');
        } catch (e) {
            let message = '';

            if (e && e._isOpenTicketLogMessage) {
                if (e.slug) {
                    message = this.$t(e.slug, e.data || {}) as string;
                } else if (e.data.exception && e.data.exception.slug) {
                    message = this.$t(
                        e.data.exception.slug,
                        e.data.exception
                    ) as string;
                }
            }

            if (!message.length) {
                message = this.$t(
                    'shop.components.shop_group_item.error.add'
                ) as string;
            }

            this.$shop.notifications.send({
                message,
                type: 'is-danger',
            });
        } finally {
            this.inFlightCount -= groupSize;
            this.isLoading = false;
        }
    }

    private async _remove() {
        if (this.count < 1) {
            return;
        }

        this.isLoading = true;

        const { groupSize } = this;
        this.inFlightCount -= groupSize;

        try {
            const event: CartItemsEvent = await this.remove();
            this.update(event);
            this.$emit('change');
        } catch (e) {
            this.$shop.notifications.send({
                message: 'Failed to remove item from cart',
                type: 'is-danger',
            });
        } finally {
            this.inFlightCount += groupSize;
            this.isLoading = false;
        }
    }
}
